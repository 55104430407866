:root {
  --main-blue-color: rgb(56, 105, 197);
}

.App {
  text-align: center;
}

/* For screens smaller than or equal to 600px, phones */
@media only screen and (max-width: 600px) {
  .logo {
    width: 90vw;
    height: auto;
    pointer-events: none;
  }

  .NameInput {
    color: white;
    background-color: #282c34;
    border: 1px solid white;
    text-align: center;
    width: 75vw;  
    height: 45px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }

  .StyledButton {
    color: white;
    background-color: var(--main-blue-color);
    border: 1px solid white;
    width: 75vw;  
    height: 45px;
    margin-bottom: 20px;
  }

  .DisabledButton {
    color: white;
    background-color: grey;
    border: 1px solid white;
    width: 75vw;  
    height: 40px;
    margin-bottom: 20px;
  }

  .Die {
    height: 12vw;
    width: 12vw;
    margin: 0 5px;
  }

  .GameBody {
    margin-top: 20vh;
  }
  
  .Lobby {
    margin-top: 20vh;
  }
}

/* For screens larger than 600px, desktops */
@media only screen and (min-width: 601px) {
  .logo {
    height: 50vmin;
    width: 50vmin;
    pointer-events: none;
  }

  .NameInput {
    color: white;
    background-color: #282c34;
    border: 1px solid white;
    text-align: center;
    width: 20vw;
    min-width: 300px;
    margin-bottom: 20px;
  }

  .StyledButton {
    color: white;
    background-color: var(--main-blue-color);
    border: 1px solid white;
    width: 20vw;
    min-width: 300px;
    margin-bottom: 20px;
  }

  .DisabledButton {
    color: white;
    background-color: grey;
    border: 1px solid white;
    width: 20vw;
    min-width: 300px;
    margin-bottom: 20px;
  }

  .Die {
    height: 8vw;
    width: 8vw;
    margin: 0 5px;
  }

  .GameBody {
    margin-top: 25vh;
  }
  
  .Lobby {
    margin-top: 25vh;
  }
}

.Scoreboard {
  position: fixed;
  bottom: 25px;
  border: 1px solid black;
  padding: 5px;
}

.ScoreTable {
  display: block;
}

.NavBar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;
  padding-left: 10px;
  min-height: 40px;
  background-color: var(--main-blue-color);
}

.NavBarItem {
  font-size: x-large;
  display: inline;
  margin-right: 25px;
}

.DisabledButton {
  background-color: grey;
}

.SelectedDie {
  color: yellow;
}

.ForkledDie {
  color: red;
}

.WinnerModal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.WinnerModalContent {
  background-color: var(--main-blue-color);
  margin: 45vh auto; /* 45% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 60%; /* Could be more or less, depending on screen size */
}

.PlayerList {
  margin-bottom: 100px;
}

/* Display Svgs inline by using flex and row direction */
.DiceSvg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.CurrentPlayer {
  color: yellow;
}

html, body {
  background-color: #282c34;
  overscroll-behavior-y: contain; /* Maybe not needed anymore */
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
}

.DiceRemain {
  animation-duration: 0.1s;
  animation-name: slidein;
  animation-iteration-count: 5;
  animation-direction: alternate;
}

@keyframes slidein {
  from {
    margin-left: 5%;
    width: 110%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.App-Body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
